<template>
  <v-card
    flat
    class="box-shadow-soft post-item"
    @click="redirectToWeb(data.url)"
  >
    <div
      v-if="data.picture"
      class="px-3 py-2 pb-3"
    >
      <profile-chip
        :platform="data.platform"
        :data="data"
      />
    </div>
    <div
      v-else
      style="height: 56px;"
    ></div>

    <v-img
      :src="proxyUrl(data.thumbnail)"
      crossorigin="anonymous"
      height="300"
      contain
    />

    <v-card-text>
      <div
        class="d-flex justify-space-between align-center"
        style="height: 32px;"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
            >
              {{ dayjs(data.posted_at).format("ll") }}
            </span>
          </template>

          <span>
            {{ dayjs(data.posted_at).format("LLL") }}
          </span>
        </v-tooltip>

        <v-tooltip
          v-if="data.audience_credibility"
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              color="secondary"
              v-bind="attrs"
              v-on="on"
              dark
            >
              <v-icon left>
                speed
              </v-icon>

              {{ (data.audience_credibility * 100).toFixed(2) + '%' }}
            </v-chip>
          </template>

          <span>
            Audience Credibility
          </span>
        </v-tooltip>
      </div>

      <v-divider class="my-4" />

      <div v-if="data.caption">
        {{ data.caption.slice(0, 99) }}...
      </div>
    </v-card-text>

    <v-card-actions class="px-5 py-3 post-card-stats font-weight-bold">
      <div class="d-flex">
        <v-icon size="20">thumb_up</v-icon> &nbsp;
        {{ nFormatter(data.likes) }}
      </div>

      <v-spacer></v-spacer>

      <template v-if="data.views">
        <div class="d-flex">
          <v-icon size="20">play_circle</v-icon> &nbsp;
          {{ nFormatter(data.views) }}
        </div>

        <v-spacer></v-spacer>
      </template>

      <div class="d-flex">
        <v-icon size="20">comment_bank</v-icon> &nbsp;
        {{ nFormatter(data.comments) }}
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
// Import children components
const ProfileChip = () => import(/* webpackChunkName: "profile-chip" */ "@/blocks/common/ProfileChip")

// Export the SFC
export default {
  // Name of the component
  name: "CRMPost",

  // Accept incoming data from parent
  props: {
    data: {
      type: Object,
      required: true
    }
  },

  // Register children components
  components: {
    ProfileChip
  }
}
</script>

<style lang="stylus" scoped>
.post-item
  .post-card-stats
    font-size 0.825em

  .post-card-stats, .post-card-stats .v-icon
    color rgba(145, 158, 171, 0.8) !important

  .v-card__text
    height 165px !important

  @media screen and (min-width 1264px)
    width 280px
</style>
